<template>
  <div class="app-container">
    <!-- 专家信息展示 -->
    <div class="personInfo" ref="personInfo">
      <el-descriptions title="个人信息" :labelStyle="{'width':'15%'}" :contentStyle="{'width':'35%'}" :column='2' border>
        <el-descriptions-item label="姓名">{{infoShow.name}}</el-descriptions-item>
        <el-descriptions-item label="单位">{{infoShow.university}}</el-descriptions-item>
        <el-descriptions-item label="邮箱">
          {{infoShow.mail}}
        </el-descriptions-item>
        <el-descriptions-item label="手机号">{{infoShow.mobile}}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="desc" title="学术信息" :labelStyle="{'width':'15%'}" :contentStyle="{'width':'35%'}"
        :column='2' border>
        <el-descriptions-item label="职称">
          {{$store.state.mapper.titleMap[infoShow.title]}}
        </el-descriptions-item>
        <el-descriptions-item label="导师类型">
          {{$store.state.mapper.typeMap[infoShow.type]}}
        </el-descriptions-item>
        <el-descriptions-item label="人才称号">{{infoShow.honors}}</el-descriptions-item>
         <el-descriptions-item label="研究方向">{{infoShow.expert_theme}}</el-descriptions-item>
        <el-descriptions-item label="关键字" :span='2'>
          {{infoShow.keywords}}
        </el-descriptions-item>
        <el-descriptions-item label="评审学术型研究生学科" v-if="infoShow.available=='是'">{{infoShow.major}}</el-descriptions-item>
        <el-descriptions-item label="评审专业学位研究生学科" v-if="infoShow.availableMaster=='是'">{{infoShow.customMajorMaster}}</el-descriptions-item>
        <el-descriptions-item label="评审本科专业" v-if="infoShow.availableUndergraduate=='是'">{{infoShow.customMajorUndergraduate}}</el-descriptions-item>
        
      </el-descriptions>
      <el-descriptions class="desc" title="评审费支付信息" :labelStyle="{'width':'15%'}" :contentStyle="{'width':'85%'}"
        :column='1' border>
        <el-descriptions-item label="支付方式">{{payMethod[infoShow.defaultPayType].text}}</el-descriptions-item>
        <el-descriptions-item label="开户人">
          {{infoShow.bankName}}</el-descriptions-item>
        <el-descriptions-item :label="idvalue">{{infoShow.IDNum}}</el-descriptions-item>
        <el-descriptions-item :label="payMethod[infoShow.defaultPayType].text+'号'">
          {{infoShow[payMethod[infoShow.defaultPayType].value]}}</el-descriptions-item>
      </el-descriptions>
      <!-- 点击修改专家信息 -->
      <div style="margin:10px">
        <el-button type="primary" @click="changeHandle" icon="el-icon-edit" size='small'>修改信息</el-button>
        <change-info :expertId='expertId'></change-info>
      </div>
    </div>
  </div>
</template>

<script>
  import changeInfo from '@/components/personalInfo/changeInfo.vue';
  import toolNull from '@/tools/null';
  export default {
    name: 'personalInfo',
    data() {
      return {
        changeDiaVis: false,
        payMethod: {
          0: {
            text: '支付宝',
            value: 'alipayId'
          },
          1: {
            text: '微信',
            value: 'wechatId'
          },
          2: {
            text: '银行卡',
            value: 'bankCardNum'
          }
        },
        idvalue:'身份证号',
        infoShow: {
          name: '',
          university: '',
          mobile: '',
          mail: '',
          title: '',
          type: '',
          major: '',
          honors: '',
          keywords: '',
          bankCardNum: '',
          bank: '',
          IDNum: '',
          url: '',
          alipayId: '',
          wechatId: '',
          defaultPayType: 0 
        },
        expertId: '',
        sign: {
          width: 400,
          height: 200,
          lineWidth: 2,
          lineColor: '#000000',
          bgColor: '',
          img: '',
          isCrop: true
        },
      }
    },
    watch: {
      '$store.state.professor.isChangeDiaVis': function () {
        this.get_expert_info();
      }
    },
    created() {
      // this.expertId = sessionStorage.getItem("expertId");
      this.get_expert_info();
    },
    mounted() {},
    updated() {},
    methods: {
      //获取专家信息
      isNull(x)
      {
        return x == null?'':x;
      },
      get_expert_info() {
        this.$api.info.getExpert().then(res => {
          if (res.data.code == 200) {
            this.infoShow = {
              name: toolNull.null2Empty(res.data.data.name),
              university: toolNull.null2Empty(res.data.data.university),
              mobile:toolNull.null2Empty(res.data.data.mobile),
              mail:toolNull.null2Empty(res.data.data.mail),
              title: toolNull.null2Empty(res.data.data.title),
              type:toolNull.null2Empty(res.data.data.type),
              major: res.data.data.customMajor == null ? '' : res.data.data.customMajor.split('%').map(item=>item.split(',')[2]).join('，'),
              customMajorMaster: res.data.data.customMajorMaster == null ?'':  res.data.data.customMajorMaster.split('%').map(item=>item.split(',')[2]).join('，'),
              customMajorUndergraduate: res.data.data.customMajorUndergraduate == null ? '' : res.data.data.customMajorUndergraduate.split('%').map(item=>item.split(',')[2]).join('，'),
              expert_theme:toolNull.null2Empty(res.data.data.expert_theme),
              honors: toolNull.null2Empty(res.data.data.honors).split(',').join(' 、'),
              keywords: toolNull.null2Empty( res.data.data.customKeywords).split(',').join(' 、'),
              bankCardNum: toolNull.null2Empty(res.data.data.bankCardNum),
              bank: toolNull.null2Empty(res.data.data.bank),
              bankName: toolNull.null2Empty(res.data.data.bankName),
              IDNum: toolNull.null2Empty(res.data.data.idnum),
              url: toolNull.null2Empty(res.data.data.url),
              alipayId:toolNull.null2Empty(res.data.data.alipayId),
              wechatId:toolNull.null2Empty( res.data.data.wechatId),
              defaultPayType: toolNull.null2Empty(res.data.data.defaultPayType),
              available:res.data.data.available == null ? '是':res.data.data.available,
              availableMaster:res.data.data.availableMaster == null ? '否':res.data.data.availableMaster,
              availableUndergraduate:res.data.data.availableUndergraduate == null ? '否':res.data.data.availableUndergraduate,
            };
            this.infoShow.defaultPayType = 2;
            // let reg = /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/;
            let reg = /^[1-9]\d{5}(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}[\dXx]$/;
            if(reg.test(this.infoShow.IDNum)){
                // this.idvalue = '护照号';
              this.idvalue = "身份证号";
            }else{
              // this.idvalue = "身份证号";
              this.idvalue = '护照号';
            }
          }else{
            this.$message.warning("专家信息获取失败！");
          }
        }).catch(err => {
          this.$message.warning("服务器维护！");
        })
      },
      //修改信息
      changeHandle() {
        this.$store.dispatch('professor/changeIsChangeDiaVis', true);
      }
    },
    components: {
      'change-info': changeInfo
    }
  }
</script>

<style scoped>
  .desc {
    margin-top: 15px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }

  .divider {
    background-color: #409EFF;
  }

  .diaContent {
    margin-right: 50px;
  }

  .keywords {
    width: 20%;
    float: left;
    margin-bottom: 5px;
  }

  .form-line-item {
    width: 100%;
  }
</style>

<style>
  .dialog .el-dialog {
    margin-top: 5vh !important;
    border-radius: 15px;
  }

  .dialog .el-dialog__body {
    padding-top: 10px;

  }

  .inputChange1 .el-input__inner {
    font-size: 12px;
    /* padding: 0px; */
  }

  .set_content_width .el-form-item__content {
    width: 75%;
  }

  .personInfo{
    background-color: #fff;
    height: calc(100vh - 110px);
    padding: 10px;
  }
</style>